import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import StartProject from '../../components/Common/StartProject';
import SEO from '../../components/App/SEO';
import galleryMetaImage from '../../assets/images/gallery/gallery-meta-image.jpg';
import Helmet from 'react-helmet';
import Gallery from '../../components/UiComponent/gallery'

export default () => {

  return (
	<Layout>
	  <Helmet>
		<SEO
		  title="ハッシュタグキャンペーン｜Insgaramギャラリーサンプル"
		  desc="個人から中小企業・大企業まで、Twitter・Instagramのハッシュタグコンテンツを収集します。投票機能でフォトコンテストも簡単に開催"
		  banner={galleryMetaImage}
		  pathname={'/gallery/instagram-example'}
		  type={'Article'}
		  shortName="UGCハッシュタグサンプル"
		  createdAt="2021-07-26"
		/>
	  </Helmet>
	  <Navbar />
	  <PageBanner
		pageTitle="Twitterギャラリーサンプル"
		homePageText="Home"
		homePageUrl="/"
		activePageText="Twitterギャラリーサンプル"
		subDirectories={[{
		  url: '/gallery',
		  name: 'ギャラリー'
		}]}
	  />
	  <section className="solutions-area pt-100 pb-70">
		<div className="container">
		  <Gallery id={"19g6ikqm36h3a"} visible={true}/>
		</div>
	  </section>
	  <StartProject/>
	  <Footer />
	</Layout>
  );
};
